<template>
  <div class="edit chat">
    <div class="add-top-title">
      <span @click="backHome()"><van-icon name="arrow-left" /></span>
      <span>编辑话术并发送</span>
      <span></span>
    </div>
    <div>
      <div class="add-item-title">{{nowInfo.title}}</div>
      <div class="add-item-box add-item-margin">
        <van-field
                v-model="nowInfo.content"
                rows="8"
                autosize
                type="textarea"
                maxlength="200"
                placeholder="请输入回答的话术"
                show-word-limit
        />
      </div>
      <div @click="sendToUser" class="edit-send-button">发送</div>
    </div>
  </div>
</template>

<script>
import {getJsSdkConfig} from '@/api/service'
/*import { Toast } from 'vant';*/
import Cookies from 'js-cookie'
const wx = window.wx;
export default {
  name: 'Edit',
  components: {
  },
  data() {
    return {
      userId: '', // 用户id
      nowInfo: {}, // 当前用户信息
      checked: false,
      boxBorder: 'add-item-box',
      num: 0,
      numColor: 'field-num',
      configPostData: {
        agentId: 1000069,
        url: ''
      },
    }},
  created() {
    let userId=Cookies.get('userId')
    const query = this.$route.query
    this.userId = userId ? userId : ''
    this.nowInfo = query
    if(!userId){
      this.$router.push('/');
      return
    }
    this.getConfig()
  },
  mounted:function(){

  },
  methods: {
    // 获取sdk  配置
    getConfig() {
      let that = this
      this.configPostData.url = window.location.href;
      getJsSdkConfig(this.configPostData).then(response => {
        that.initAgentConfig(response.data);
      })
    },
    // 初始化 agentConfig
    initAgentConfig(jsSdkConfig) {
      /*let that = this*/
      wx.agentConfig({
        corpid: jsSdkConfig.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: '1000069', // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: jsSdkConfig.timestamp, // 必填，生成签名的时间戳
        nonceStr: jsSdkConfig.nonceStr, // 必填，生成签名的随机串
        signature: jsSdkConfig.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['getCurExternalContact','sendChatMessage','getContext'], //必填，传入需要使用的接口名称
        success: function() {
          // 回调
          console.log('初始化agent 成功')
        },
        fail: function(res) {
          console.log(res)
          if(res.errMsg.indexOf('function not exist') > -1){
            alert('版本过低请升级')
          }
        }
      });
    },
    // 发送消息到客户聊天窗口
    sendToUser() {
      /*let that = this*/
      let message = this.nowInfo.content
      if(!message){
        return
      }
      wx.invoke('sendChatMessage', {
        msgtype: "text", //消息类型，必填
        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
        text: {
          content: message, //文本内容
        },
      },function(res) {
        if (res.err_msg == 'sendChatMessage:ok') {
          console.log('ok')
        }
      })
    },
    // 监听是否输入超过字段限制
    showNotice() {
      let nowNum = this.addData.title.length
      this.num = nowNum
      if(nowNum > 200){
        this.boxBorder = 'add-item-box2'
        this.numColor = 'field-num1'
      }else{
        this.boxBorder = 'add-item-box'
        this.numColor = 'field-num'
      }
    },
    // 返回首页
    backHome() {
      this.$router.push('/');
    }
  }
}
</script>

<style>
  .chat{
    background-color:#ffffff;
    padding: 20px;
  }
  .add-top-title{
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #0A0A0A;
    line-height: 20px;
    padding: 0px 0 15px 0;
    border-bottom: 1px dashed #d4d7d7;
    display: flex;
    justify-content: space-between;
  }
  .edit .add-item-title{
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    margin: 15px 0;
  }
  .add-item-box{
    border: 1px solid #D9D9D9;
  }
  .add-item-margin{
    margin-top: 15px;
  }
  .edit-send-button{
    width: 90vw;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(136deg, #20DE7B 0%, #07C160 100%);
    border-radius: 4px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
  }
</style>
